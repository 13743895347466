import React from 'react';

export default function USA(props: React.SVGAttributes<{}>) {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 130 120" enableBackground="new 0 0 130 120" xmlSpace="preserve" fill="#000000" {...props}>
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                {' '}
                <g id="Infos">
                    {' '}
                    <rect id="BG" x="-200" y="-1350" fill="#D8D8D8" width="2180" height="1700"></rect>{' '}
                </g>{' '}
                <g id="Others">
                    {' '}
                    <g>
                        {' '}
                        <rect y="0" fill="#DC4437" width="130" height="13.3"></rect>
                        <rect y="26.7" fill="#DC4437" width="130" height="13.3"></rect>
                        <rect y="80" fill="#DC4437" width="130" height="13.3"></rect> <rect y="106.7" fill="#DC4437" width="130" height="13.3"></rect>
                        <rect y="53.3" fill="#DC4437" width="130" height="13.3"></rect>
                        <rect y="13.3" fill="#FFFFFF" width="130" height="13.3"></rect> <rect y="40" fill="#FFFFFF" width="130" height="13.3"></rect>
                        <rect y="93.3" fill="#FFFFFF" width="130" height="13.3"></rect>
                        <rect y="66.7" fill="#FFFFFF" width="130" height="13.3"></rect> <rect y="0" fill="#2A66B7" width="70" height="66.7"></rect>
                        <polygon fill="#FFFFFF" points="13.5,4 15.8,8.9 21,9.7 17.2,13.6 18.1,19 13.5,16.4 8.9,19 9.8,13.6 6,9.7 11.2,8.9 "></polygon>{' '}
                        <polygon fill="#FFFFFF" points="34,4 36.3,8.9 41.5,9.7 37.8,13.6 38.6,19 34,16.4 29.4,19 30.2,13.6 26.5,9.7 31.7,8.9 "></polygon>{' '}
                        <polygon fill="#FFFFFF" points="54.5,4 56.8,8.9 62,9.7 58.2,13.6 59.1,19 54.5,16.4 49.9,19 50.8,13.6 47,9.7 52.2,8.9 "></polygon>{' '}
                        <polygon fill="#FFFFFF" points="24,24 26.3,28.9 31.5,29.7 27.8,33.6 28.6,39 24,36.4 19.4,39 20.2,33.6 16.5,29.7 21.7,28.9 "></polygon>{' '}
                        <polygon fill="#FFFFFF" points="44.5,24 46.8,28.9 52,29.7 48.2,33.6 49.1,39 44.5,36.4 39.9,39 40.8,33.6 37,29.7 42.2,28.9 "></polygon>{' '}
                        <polygon fill="#FFFFFF" points="13.5,45.2 15.8,50.1 21,50.9 17.2,54.7 18.1,60.2 13.5,57.6 8.9,60.2 9.8,54.7 6,50.9 11.2,50.1 "></polygon>{' '}
                        <polygon fill="#FFFFFF" points="34,45.2 36.3,50.1 41.5,50.9 37.8,54.7 38.6,60.2 34,57.6 29.4,60.2 30.2,54.7 26.5,50.9 31.7,50.1 "></polygon>{' '}
                        <polygon fill="#FFFFFF" points="54.5,45.2 56.8,50.1 62,50.9 58.2,54.7 59.1,60.2 54.5,57.6 49.9,60.2 50.8,54.7 47,50.9 52.2,50.1 "></polygon>{' '}
                    </g>{' '}
                </g>{' '}
                <g id="Europe">
                    {' '}
                    <g id="Row_5"></g>
                    <g id="Row_4"></g>
                    <g id="Row_3"></g>
                    <g id="Row_2"></g>
                    <g id="Row_1"></g>{' '}
                </g>{' '}
            </g>
        </svg>
    );
}